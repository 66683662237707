let tableColumns = {
  data: {
    columns: [
      {
        label: "First Name",
        field: "firstName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Last Name",
        field: "lastName",
        sort: "asc",
        width: 100,
      },

      {
        label: "Email Address",
        field: "emailAddress",
        sort: "asc",
        width: 100,
      },
      {
        label: "Gender",
        field: "gender",
        sort: "asc",
        width: 50,
      },
      {
        label: "Branch",
        field: "branch",
        sort: "asc",
        width: 50,
      },
      {
        label: "Unit",
        field: "unit",
        sort: "asc",
        width: 50,
      },
      {
        label: "Service End Date",
        field: "serviceEndDate",
        sort: "asc",
        width: 50,
      },
      {
        label: "Delete",
        field: "delete",
        sort: "asc",
        width: 50,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 50,
      },
    ],
  },
};
export { tableColumns };
