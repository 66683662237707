import { React } from "react";
import { Button, Modal, Col, Row, Form } from "react-bootstrap";
import { MentorForm } from "../../components/forms/mentor-form";
import "./modal.css";

export const MentorsModal = ({ setLgShow, lgShow, selectedMentor, setSelectedMentor, getData, isEdit=false }) => {

  const handleAddClicked = () => {
    setSelectedMentor("");
    setLgShow(true);
  }

  return (
    <Col>
      <Row className='w-100'>
        {!isEdit && <h1>Mentors</h1>}
        <Col>
          <div className="button-container">
            {!isEdit && 
            <Button
              style={{ color: '#004B80', borderColor: '#004B80' }}
              variant='outlined'
              size='large'
              onClick={() => handleAddClicked()}
            >
              Add Mentor
            </Button>}
          </div>
        </Col>
        <Modal
          scrollable="true"
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Add Mentor
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <MentorForm
                selectedMentor={selectedMentor}
                setLgShow={setLgShow}
                getData={getData}
                isEdit={isEdit}
              />
            </Form>
          </Modal.Body>
        </Modal>
      </Row>
    </Col>
  );
};
