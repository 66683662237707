import * as React from "react";
import { useState } from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Label from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { Row, Button } from "react-bootstrap";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "react-datepicker/dist/react-datepicker.css";
import { setDoc, doc, updateDoc, collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import moment from "moment";
import { Alert } from '../../components/alert';
import "../forms/index.css";

export const MentorForm = ({ selectedMentor, setLgShow, getData, isEdit=false }) => {
  const auth = getAuth();
  const [error, setError] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [resetResponse, setResetResponse] = useState({status: null, message: ""})

  const [firstName, setFirstName] = useState(
    selectedMentor ? selectedMentor.firstName : ""
  );
  const [lastName, setLastName] = useState(
    selectedMentor ? selectedMentor.lastName : ""
  );
  const [email, setEmail] = useState(
    selectedMentor ? selectedMentor.emailAddress : ""
  );
  const [oldEmail, setOldEmail] = useState(selectedMentor ? selectedMentor.emailAddress : "");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState(
    selectedMentor ? selectedMentor.gender : ""
  );
  const [branch, setBranch] = useState(
    selectedMentor ? selectedMentor.branch : ""
  );
  const [unit, setUnit] = useState(selectedMentor ? selectedMentor.unit : "");
  const [servEndDate, setServEndDate] = useState(new Date());

  const handleSubmit = async () => {

    async function createMentor(mentor) {
      console.log("create mentor")
      return await createUserWithEmailAndPassword(auth, email, password)
        .then(async (result) => {
          var newUid = result.user.uid;
          const newMentorRef = doc(db, "mentorUids", newUid);
          await setDoc(newMentorRef, mentor);
          return true;
        })
        .catch((e) => {
          setError(e.message);
          setShowAlert(true);
          return false;
        })
    }

    async function updateMentor(mentor, uid) {
      console.log("update mentor")

      try {
        const updateMentorRef = doc(db, "mentorUids", uid);
        await setDoc(updateMentorRef, mentor, { merge: true });
        if(email !== oldEmail) {
          await createChangeEmailTask();
        }
        return true        
      } catch(e) {
        setError(e.message);
        setShowAlert(true);
        return false;
      }
    }
    
    const mentor = {
      IsMentor: true,
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Gender: gender,
      Branch: branch,
      Unit: unit,
      ServEndDate: moment(servEndDate).format("MM-DD-YYYY"),
    };

    var result = false;
    if (!selectedMentor) {
      result = await createMentor(mentor);
    } else {
      result = await updateMentor(mentor, selectedMentor.uid);
    }

    if( result ) {
      setLgShow(false);
      getData();  
    }
  };

  const onPasswordReset = async () => {
    console.log("password reset")
    try {
      await createPasswordResetTask();
      return setResetResponse({status: "success", message: "Reset request sent to the server"});
    } catch(error) {
      return setResetResponse({status: "error", message: "Error sending reset request to the server"})
    }
  };

  const createPasswordResetTask = async () => {
    const taskRef = collection(db, "ResetPasswordTasks");
    try {
      await addDoc(taskRef, {uid: selectedMentor.uid, email: email});
      console.log("successfully created task")
      return true
    } catch (error) {
        console.log("error creating reset password task", error)
    }
  };

  const createChangeEmailTask = async () => {
    const taskRef = collection(db, "ChangeEmailTasks");
    try {
      await addDoc(taskRef, {uid: selectedMentor.uid, oldEmail: oldEmail, newEmail: email});
      console.log("successfully created task")
      return true
    } catch (error) {
        console.log("error creating change email task", error)
    }
  };

  return (
    <div>
      <Alert show={showAlert} 
             close={() => setShowAlert(false)} 
             title={'Add/Edit Mentor Error'} 
             message={'There was an error adding/updating the mentor. ' + error}
      />

      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <Row>
          <Label id="standard-label">
            <div className="resInfo">General Information</div>
          </Label>
        </Row>
        <Row>
          <div style={{ display: "flex" }}>
            <TextField
              id="standard-textarea"
              label="First Name"
              placeholder=""
              multiline
              maxRows={2}
              value={firstName}
              variant="standard"
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              id="standard-textarea"
              label="Last Name"
              value={lastName}
              placeholder=""
              multiline
              variant="standard"
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </Row>
        <Row>
          <div>
            <TextField
              label="Email Address"
              value={email}
              placeholder=""
              variant="standard"
              maxRows={1}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </Row>
        <Row>
          <div style={{marginTop: 20, marginBottom: 20,}}>
            {selectedMentor ?
              <div>
                <Button className="reset-button" variant="primary" onClick={onPasswordReset}>
                  RESET PASSWORD
                </Button>
                <p style={{color: resetResponse.status === "success" ? "green" : "red", marginTop: 10}}>{resetResponse.message}</p>
              </div>
            :
              <TextField
                label="Password"
                value={password}
                placeholder=""
                variant="standard"
                maxRows={1}
                onChange={(e) => setPassword(e.target.value)}
              />
            }
          </div>
        </Row>
        <div>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">Gender</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              label="Gender"
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Row>
          <Label id="standard-label">
            <div className="resInfo">Military Information</div>
          </Label>
        </Row>
        <Row>
          <div style={{ display: "flex" }}>
            <TextField
              id="standard-textarea"
              label="Branch"
              value={branch}
              placeholder=""
              multiline
              maxRows={2}
              variant="standard"
              onChange={(e) => setBranch(e.target.value)}
            />
            <TextField
              id="standard-textarea"
              label="Unit"
              placeholder=""
              value={unit}
              multiline
              variant="standard"
              onChange={(e) => setUnit(e.target.value)}
            />
          </div>
        </Row>
        <DatePicker selected={servEndDate} onChange={(e) => setServEndDate(e)} />
        <Button className="my-save" variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
        <Button
          className="my-cancel"
          size="large"
          variant="secondary"
          onClick={() => setLgShow(false)}
        >
          Cancel
        </Button>
      </Box>
    </div>
  );
};
