import React, { useState, useEffect } from 'react';
import { setDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './config.css';
import { LinkInput } from '../../components/inputs/link-input';

export function Config() {

    const [videoId, setVideoId] = useState("");
    const [url, setUrl] = useState("");
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [validId, setValidId] = useState(false)

    const updateUrl = (value) => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        let match = value.match(regExp);
        console.log("match", match)
        match && setVideoId(match[7]);
        setUrl(value)
        setValidId(match && match[7].length === 11);
    };

    const onSave = () => {
        setSuccessMessage("");
        setError("");
        saveVideoId();
    }

    useEffect(() => {
        console.log("video id", videoId)
    },[videoId])

    const saveVideoId = async () => {
        const configRef = doc(db, "Config", "Text");
        if(videoId && validId) {
            try {
                await setDoc(configRef, {SuicideHotlineVideoId: videoId}, {merge: true});
                setSuccessMessage("UPDATED!")
                setError("")
            } catch (error) {
                console.log("Error Updating DB")
            }
            
        } else if(!validId) {
            setError("Url must have a valid Video Id");
        } else {   
            setError("Video Id cannot be null");
        }
    }

    return (
        <div className={"container"} style={{width: "50%"}}>
            <LinkInput 
                path="SuicideHotlineVideoId"
                title="Suicide Hotline Video URL"
            />
            <LinkInput 
                path="ChatNowVideoId"
                title="Chat Now Video URL"
            />
        </div>
    )
}
