let tableColumns = {
  data: {
    columns: [
      {
        label: 'Title',
        field: 'title',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Website',
        field: 'website',
        sort: 'asc',
        width: 50,
      },
      {
        label: 'Phone Number',
        field: 'phoneNumber',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 50,
      },
      {
        label: 'Description',
        field: 'description',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'Image',
        field: 'image',
        sort: 'asc',
        width: 50,
      },
      {
        label: 'Edit',
        field: 'edit',
        sort: 'asc',
        width: 50,
      },

      {
        label: 'Delete',
        field: 'delete',
        sort: 'asc',
        width: 50,
      },
    ],
  },
};
export { tableColumns };
