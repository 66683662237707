import { Modal, Button } from 'react-bootstrap';
import { doc, deleteDoc, addDoc, collection } from 'firebase/firestore';
import { db } from '../../../firebase';

import 'firebase/firestore';

export const Delete = ({ show, close, currentMentorId, getData }) => {

    const createMentorAuthDeleteTask = async () => {
        const mentorRef = collection(db, "DeleteTasks");

        try {
          await addDoc(mentorRef, {uid: currentMentorId});
          return true
        } catch (error) {
            console.log("error creating mentor delete task", error)
        }
    }
  

    const confirmDelete = async (id) => {
        await createMentorAuthDeleteTask();
        await deleteDoc(doc(db, 'mentorUids', id));
        // we should be deleting the Auth records here -- troy
        // https://stackoverflow.com/questions/38800414/delete-a-specific-user-from-firebase
        close();
        await getData();
    };

    return (
        <div>
            <Modal show={show} onHide={close} backdrop='static' keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Please Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this mentor?</Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => close()}>
                        Cancel
                    </Button>
                    <Button
                        variant='primary'
                        onClick={() => confirmDelete(currentMentorId)}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
