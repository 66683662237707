import { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { convertToNumber } from '../../../Services/Resources/ConvertToNumber';
import { db } from '../../../firebase';
import '../Add/index.css';
import { Modal, Row } from 'react-bootstrap';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

export default function Add({ show, handleAddClose, getData }) {
  const [resourceObj, setResourceObj] = useState({});

  const handleChange = (event) => {
    setResourceObj({ ...resourceObj, [event.target.name]: event.target.value });
  };

  const modalCancel = () => {
    handleAddClose();
    setResourceObj({});
  };

  const submitData = async (e) => {
    try {
      const newObject = await convertToNumber(resourceObj);
      await handleAddClose();
      await addDoc(collection(db, 'Resources'), newObject);
      await getData();
      setResourceObj({});
    } catch (error) {
      console.log('Not able to add Resource', error);
    }
  };

  return (
    <>
      <div className='modal'>
        <Modal
          show={show}
          onHide={handleAddClose}
          dialogClassName='my-modal'
          aria-labelledby='example-custom-modal-styling-title'
          backdrop='static'
          keyboard={false}
        >
          <Modal.Title id='contained-modal-title-vcenter' className='title'>
            Add Resource
          </Modal.Title>
          <Modal.Body className='show-grid'>
            <form onSubmit={submitData}>
              <Row>
                <div className='resInfo'>Resource Information</div>
                <Box
                  className='input-container'
                  component='form'
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete='off'
                >
                  <TextField
                    className='text-field'
                    id='standard-basic'
                    label='Title'
                    variant='standard'
                    name='Name'
                    value={resourceObj.Title}
                    onChange={handleChange}
                  />
                  <TextField
                    className='text-field'
                    id='standard-basic'
                    label='Website'
                    variant='standard'
                    name='Url'
                    value={resourceObj.Url}
                    onChange={handleChange}
                  />
                </Box>
              </Row>
              <Row>
                <Box
                  className='input-container'
                  component='form'
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete='off'
                >
                  <TextField
                    className='text-field'
                    id='standard-basic'
                    label='Email Address'
                    variant='standard'
                    name='Email'
                    value={resourceObj.Email}
                    onChange={handleChange}
                  />
                  <TextField
                    className='text-field'
                    id='standard-basic'
                    label='Phone Number'
                    variant='standard'
                    name='Phone'
                    value={resourceObj.Phone}
                    onChange={handleChange}
                  />
                </Box>
              </Row>
              <Row>
                <Box
                  className='input-container'
                  component='form'
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete='off'
                >
                  <TextField
                    className='text-field'
                    id='standard-basic'
                    label='Picture URL'
                    variant='standard'
                    name='PictureUrl'
                    value={resourceObj.PictureUrl}
                    onChange={handleChange}
                  />
                  <TextField
                    className='text-field'
                    id='standard-basic'
                    label='Group Number'
                    variant='standard'
                    name='Group'
                    value={resourceObj.Group}
                    onChange={handleChange}
                  />
                </Box>
              </Row>
              <Row>
                <Box
                  className='bottom-input-container'
                  component='form'
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete='off'
                >
                  <TextField
                    className='text-field'
                    style={{ marginLeft: '16px' }}
                    id='standard-basic'
                    label='Order Number'
                    variant='standard'
                    name='Num'
                    value={resourceObj.Num}
                    onChange={handleChange}
                  />
                </Box>
              </Row>

              <Row>
                <Box
                  style={{
                    display: 'flex',
                  }}
                  component='form'
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete='off'
                >
                  <TextField
                    className='description'
                    id='outlined-multiline-static'
                    label='Description'
                    multiline
                    rows={4}
                    name='Description'
                    defaultValue='    '
                    value={resourceObj.Description}
                    onChange={handleChange}
                  />{' '}
                </Box>
              </Row>
              <Row style={{ display: 'flex' }}>
                <Box
                  className='button-container'
                  component='form'
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete='off'
                >
                  <Button
                    className='my-cancel'
                    variant='secondary'
                    onClick={modalCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    className='my-save'
                    variant='primary'
                    onClick={submitData}
                  >
                    Save
                  </Button>
                </Box>
              </Row>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
