import React, { useState, useEffect } from 'react';
import { setDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './style.css';

export function LinkInput({path, title}) {

    const [videoId, setVideoId] = useState("");
    const [url, setUrl] = useState("");
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [validId, setValidId] = useState(false)

    const updateUrl = (value) => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        let match = value.match(regExp);
        console.log("match", match)
        match && setVideoId(match[7]);
        setUrl(value)
        setValidId(match && match[7].length === 11);
    };

    const onSave = () => {
        setSuccessMessage("");
        setError("");
        saveVideoId();
    }

    useEffect(() => {
        console.log("video id", videoId)
    },[videoId])

    const saveVideoId = async () => {
        const configRef = doc(db, "Config", "Text");
        if(videoId && validId) {
            try {
                await setDoc(configRef, {[path]: videoId}, {merge: true});
                setSuccessMessage("UPDATED!")
                setError("")
            } catch (error) {
                console.log("Error Updating DB")
            }
            
        } else if(!validId) {
            setError("Url must have a valid Video Id");
        } else {   
            setError("Video Id cannot be null");
        }
    }

    return (
        <div style={{width: "100%", margin: 30,}}>
            {successMessage ? <p style={{color: "green"}}>{successMessage}</p>
            : error ? <p style={{color: "red"}}>{error}</p>
            : null
            }
            <TextField 
                id="outlined-basic" 
                label={title} 
                variant="outlined" 
                value={url} 
                onChange={e => updateUrl(e.target.value)}
                style={{width: "100%", marginBottom: 10, borderColor: "#0D5486", color: "#0D5486"}}
            />

            <Button 
                variant="contained"
                onClick={onSave}
                style={{width: "100%", backgroundColor: "#0D5486"}}
            >
                Save
            </Button>
        </div>
    )
}