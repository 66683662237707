import { Modal, Button } from 'react-bootstrap';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

import 'firebase/firestore';

function Delete({ show, close, currentResource_id, getData }) {
  //
  const confirmDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'Resources', id));
      close();
      await getData();
      console.log(`Resource ${id} was deleted!!!`);
    } catch (err) {
      console.log(`failed to delete resource ${err}`);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={close} backdrop='static' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Please Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this resource?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => close()}>
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={() => confirmDelete(currentResource_id)}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Delete;
