import { useState, useEffect } from 'react';
import { getDoc, updateDoc, doc } from 'firebase/firestore';
import { convertToNumber } from '../../../Services/Resources/ConvertToNumber';
import { db } from '../../../firebase';
import '../edit/index.css';
import { Modal, Row } from 'react-bootstrap';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

export default function Edit({
  show,
  handleEditClose,
  getData,
  currentResource_id,
}) {
  const [currentResource, setCurrentResource] = useState({});

  const handleChange = (event) => {
    setCurrentResource({
      ...currentResource,
      [event.target.name]: event.target.value,
    });
    console.log('testing', currentResource);
  };

  const getCurrentResource = async (id) => {
    try {
      const docRef = doc(db, 'Resources', id);
      const currentSnapshot = (await getDoc(docRef)).data();
      setCurrentResource(currentSnapshot);
    } catch (err) {
      console.log('could not get resource:', err);
    }
  };

  const updateCurrentResource = async (id) => {
    try {
      const newObject = await convertToNumber(currentResource);
      const docRef = doc(db, 'Resources', id);
      await updateDoc(docRef, newObject);
      getData();
      closeModal();
    } catch (err) {
      console.log(`Could not update record: ${id}, error: ${err}`);
    }
  };

  const closeModal = () => {
    handleEditClose();
    // setCurrentResource({});
  };

  useEffect(() => {
    getCurrentResource(currentResource_id);
    console.log('cheking mf', currentResource_id);
  }, [currentResource_id]);
  return (
    <>
      <div className='edit-modal'>
        <Modal
          show={show}
          onHide={handleEditClose}
          dialogClassName='edit-my-modal'
          aria-labelledby='example-custom-modal-styling-title'
          backdrop='static'
          keyboard={false}
        >
          <Modal.Title
            id='contained-modal-title-vcenter'
            className='edit-title'
          >
            Edit Resource
          </Modal.Title>
          <Modal.Body className='show-grid'>
            <form>
              <Row>
                <div className='edit-resInfo'>Resource Information</div>
                <Box
                  className='edit-input-container'
                  component='form'
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete='off'
                >
                  <TextField
                    className='edit-text-field'
                    id='standard-basic'
                    label='Title'
                    variant='standard'
                    name='Name'
                    value={currentResource.Name ? currentResource.Name : ''}
                    onChange={handleChange}
                  />
                  <TextField
                    className='edit-text-field'
                    id='standard-basic'
                    label='Website'
                    variant='standard'
                    name='Url'
                    value={currentResource.Url ? currentResource.Url : ''}
                    onChange={handleChange}
                  />
                </Box>
              </Row>
              <Row>
                <Box
                  className='edit-input-container'
                  component='form'
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete='off'
                >
                  <TextField
                    className='edit-text-field'
                    id='standard-basic'
                    label='Email Address'
                    variant='standard'
                    name='Email'
                    value={currentResource.Email ? currentResource.Email : ''}
                    onChange={handleChange}
                  />
                  <TextField
                    className='edit-text-field'
                    id='standard-basic'
                    label='Phone Number'
                    variant='standard'
                    name='Phone'
                    value={currentResource.Phone ? currentResource.Phone : ''}
                    onChange={handleChange}
                  />
                </Box>
              </Row>
              <Row>
                <Box
                  className='edit-input-container'
                  component='form'
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete='off'
                >
                  <TextField
                    className='edit-text-field'
                    id='standard-basic'
                    label='Picture URL'
                    variant='standard'
                    name='PictureUrl'
                    value={
                      currentResource.PictureUrl
                        ? currentResource.PictureUrl
                        : ''
                    }
                    onChange={handleChange}
                  />
                  <TextField
                    className='edit-text-field'
                    id='standard-basic'
                    label='Group Number'
                    variant='standard'
                    name='Group'
                    value={currentResource.Group ? currentResource.Group : ''}
                    onChange={handleChange}
                  />
                </Box>
              </Row>
              <Row>
                <Box
                  className='edit-bottom-input-container'
                  component='form'
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete='off'
                >
                  <TextField
                    className='edit-text-field'
                    style={{ marginLeft: '16px' }}
                    id='standard-basic'
                    label='Order Number'
                    variant='standard'
                    name='Num'
                    value={currentResource.Num ? currentResource.Num : ''}
                    onChange={handleChange}
                  />
                </Box>
              </Row>

              <Row>
                <Box
                  style={{
                    display: 'flex',
                  }}
                  component='form'
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete='off'
                >
                  <TextField
                    className='edit-description'
                    id='outlined-multiline-static'
                    label='Description'
                    multiline
                    rows={4}
                    name='Description'
                    defaultValue='    '
                    value={
                      currentResource.Description
                        ? currentResource.Description
                        : ''
                    }
                    onChange={handleChange}
                  />{' '}
                </Box>
              </Row>
              <Row style={{ display: 'flex' }}>
                <Box
                  className='edit-button-container'
                  component='form'
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete='off'
                >
                  <Button
                    className='edit-my-cancel'
                    variant='secondary'
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    className='edit-my-save'
                    variant='primary'
                    onClick={async () =>
                      await updateCurrentResource(currentResource_id)
                    }
                  >
                    Save
                  </Button>
                </Box>
              </Row>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
