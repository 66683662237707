import { useState, useEffect } from 'react';
import Table from '../../components/Resources/Table/index';
import Add from '../../components/Resources/Add/index';
import Delete from '../../components/Resources/delete/index';
import Edit from '../../components/Resources/edit/index';
import './resources.css';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { Row, Col } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { tableColumns } from '../../components/Resources/Table/table-columns';

export const Resources = () => {
  const [table, setTable] = useState({});
  const [deleteShow, setDeleteShow] = useState(false);
  const [addShow, setAddShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [currentResource_id, setCurrentResource_id] = useState(null);

  const handleEditShow = (id) => {
    setCurrentResource_id(id);
    console.log('test id', id);
    setEditShow(true);
  };

  const handleEditClose = () => {
    setEditShow(false);
  };
  const handleAddClose = () => {
    setAddShow(false);
  }

  const handleAddShow = () => {
    setAddShow(true);
  }


  const handleDeleteClose = () => {
    setDeleteShow(false);
  }
  
  const handleDeleteOpen = (id) => {
    setDeleteShow(true);
    setCurrentResource_id(id);
    console.log('checking', id);
  };

  let tableRows = {
    rows: [{}],
  };

  const getData = async () => {
    try {
      const resourcesCol = collection(db, 'Resources');
      const ResourcesSnapshot = await getDocs(resourcesCol);
      ResourcesSnapshot.forEach((doc) => {
        let entry = {
          group: doc.data().Group ? doc.data().Group : '',
          number: doc.data().Num ? doc.data().Num : '',
          uid: doc.id ? doc.id : '',
          title: doc.data().Name ? doc.data().Name : '',
          website: doc.data().Url ? doc.data().Url : '',
          phoneNumber: doc.data().Phone ? doc.data().Phone : '',
          email: doc.data().Email ? doc.data().Email : '',
          description: doc.data().Description ? doc.data().Description : '',
          image: (
            <img
              src={
                doc.data().PictureUrl
                  ? doc.data().PictureUrl
                  : doc.data().carouselImage
              }
              alt='resource'
              className='imagesize'
            />
          ),
          delete: (
            <div>
              <Button
                variant='outlined'
                size='small'
                onClick={() => {
                  handleDeleteOpen(doc.id);
                }}
              >
                Delete
              </Button>
            </div>
          ),
          edit: (
            <div>
              <Button
                variant='outlined'
                size='small'
                onClick={() => handleEditShow(doc.id)}
              >
                Edit
              </Button>
            </div>
          ),
        };
        tableRows.rows.push(entry);
      });
      setTable({ ...tableColumns.data, ...tableRows });
    } catch (error) {
      console.log(`Failed to get user, error: ${error.msg}`);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Col>
        <Row className='w-100'>
          <h1>Resources</h1>
          <div className='add-resource'>
            <Button
              style={{ color: '#004B80', borderColor: '#004B80' }}
              variant='outlined'
              size='medium'
              onClick={() => handleAddShow()}
            >
              Add Resource
            </Button>
          </div>
        </Row>
        <Row>
          <Table className='col-xl-10' data={table} getData={getData} />
        </Row>
      </Col>
      <Delete
        currentResource_id={currentResource_id}
        show={deleteShow}
        close={handleDeleteClose}
        getData={getData}
      />
      <Add
        show={addShow}
        handleAddClose={handleAddClose}
        handleAddShow={handleAddShow}
        getData={getData}
      />
      <Edit
        show={editShow}
        handleEditClose={handleEditClose}
        handleEditShow={handleEditShow}
        currentResource_id={currentResource_id}
        getData={getData}
      />
    </>
  );
};
