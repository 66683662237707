import React from 'react';
import { MDBDataTable } from 'mdbreact';

const DatatablePage = ({ data }) => {
  return (
    <MDBDataTable
      responsiveLg
      entries={8}
      hover
      fixed
      noBottomColumns
      // fixed
      displayEntries={false}
      searching={false}
      className='col-xl-12'
      data={data}
    />
  );
};

export default DatatablePage;
