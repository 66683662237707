export const convertToNumber = async (resourceObj) => {
  try {
    let placeholder = {
      ...resourceObj,
      Group: (await resourceObj.Group) && !NaN ? Number(resourceObj.Group) : '',
      Num: (await resourceObj.Num) && !NaN ? Number(resourceObj.Num) : '',
    };
    return placeholder;
  } catch (err) {
    console.log(err);
  }
};
