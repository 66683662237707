import { Container, Row } from 'react-bootstrap';

export const Inactive = () => {
  return (
    <Container className="justify-content-center pt-4 sideContainer">
      <Row className="w-100">
        <div className="d-flex align-items-center justify-content-between text-danger">
          <h2>Inactive</h2>
        </div>
      </Row>
      <Row className="w-100 m-4">
      </Row>
    </Container>
  )
}