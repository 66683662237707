import { Routes, Route } from 'react-router';
import { Home } from './routes/home';
import { Login } from './routes/login';
import PrivateRoute from './PrivateRoute';
import { AuthProvider } from './Auth';

export const Router = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route exact path='/' element={<Login />} />
        <Route
          path='/home/*'
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
      </Routes>
    </AuthProvider>
  );
};
