import React from 'react';
import logo from '../../assets/battle-buddies.png';
import { makeStyles } from '@mui/styles';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import { NavLink, useLocation } from 'react-router-dom';

import './side-nav.css';

export const SideNav = () => {
  const classes = useStyles();

  const usePathname = () => {
    const location = useLocation();
    return location.pathname;
  };

  return (
    <div className={classes.drawer}>
      <img src={logo} width='150' alt='' className={classes.navImage} />
      <List className={classes.navList}>
        <ListItem button>
          <NavLink
            className={
              usePathname() === '/home/mentors'
                ? classes.activeDrawer
                : classes.inactiveDrawer
            }
            activeClassName='active-link'
            to='/home/mentors'
            exact
          >
            <h3 className={classes.navText}>Mentors</h3>
          </NavLink>
        </ListItem>
        <ListItem button>
          <NavLink
            className={
              usePathname() === '/home/inactive'
                ? classes.activeDrawer
                : classes.inactiveDrawer
            }
            activeStyle={{ color: 'red' }}
            to='/home/inactive'
          >
            <h2 className={classes.navText}>Inactive Members</h2>
          </NavLink>
        </ListItem>
        <ListItem button>
          <NavLink
            className={
              usePathname() === '/home/resources'
                ? classes.activeDrawer
                : classes.inactiveDrawer
            }
            activeClassName='active-link'
            to='/home/resources'
            exact
          >
            <h3 className={classes.navText}>Resources</h3>
          </NavLink>
        </ListItem>
        <ListItem button>
          <NavLink
            className={
              usePathname() === '/home/analytics'
                ? classes.activeDrawer
                : classes.inactiveDrawer
            }
            activeClassName='active-link'
            to='/home/analytics'
            exact
          >
            <h3 className={classes.navText}>Analytics</h3>
          </NavLink>
        </ListItem>
        <ListItem button>
          <NavLink
            className={
              usePathname() === '/home/config'
                ? classes.activeDrawer
                : classes.inactiveDrawer
            }
            activeClassName='active-link'
            to='/home/config'
            exact
          >
            <h3 className={classes.navText}>Config</h3>
          </NavLink>
        </ListItem>
      </List>
    </div>
  );
};

const useStyles = makeStyles({
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 200,
    paddingTop: 20,
  },
  navList: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  activeDrawer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: '#0f4d92',
    padding: 10,
    color: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    '&:hover': {
      color: '#fff',
      backgroundColor: '##0f4d92',
    },
  },
  inactiveDrawer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 5,
    color: '#d80000',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    '&:hover': {
      color: '#cc0000',
    },
  },
  navImage: {
    paddingTop: 20,
    paddingBottom: 25,
  },
  navText: {
    fontSize: 13,
  },
  logoutText: {
    textAlign: 'center',
    padding: 25,
    '&:hover': {
      cursor: 'pointer',
      color: '#ff0000',
    },
  },
});
