import { makeStyles } from '@mui/styles';
import { Inactive } from '../inactive';
import { Mentors } from '../mentors';
import { Container, Row, Col } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './home.css';
import { Resources } from '../resources';
import { SideNav } from '../../components/side-nav';
import { Analytics } from '../analytics/analytics';
import {Config} from '../config';
import { auth } from '../../firebase';

export const Home = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleLogout = () => {
    auth.signOut();
    navigate('/');
  };

  return (
    <Container fluid>
      <Row className='home-row'>
        <Col xs={2} align='center' className='nav-col'>
          <SideNav />
        </Col>
        <Col xsw={10} className='page-col'>
          <div id='help-me'>
            <div className='button-container'>
              <Button
                size='large'
                variant='light'
                onClick={() => {
                  handleLogout();
                }}
              >
                Log Out
              </Button>
            </div>
            <div className='dashboardContainer'>
              <div id='page-container'>
                <Routes>
                  <Route path='/mentors' element={<Mentors />} />
                  <Route path='/inactive' element={<Inactive />} />
                  <Route path='/resources' element={<Resources />} />
                  <Route path='/analytics' element={<Analytics />} />
                  <Route path='/config' element={<Config />} />
                </Routes>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const useStyles = makeStyles({
  drawerPaper: {
    minHeight: '100vh',
    width: 175,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '6px 0 4px -4px rgba(79,79,79,0.3)',
    overflow: 'hidden',
  },
});
