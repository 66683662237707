import React from "react";
import { MDBDataTable } from "mdbreact";

export const MentorsData = ({ data }) => {
  return (
    <MDBDataTable
      responsiveLg
      entries={10}
      hover
      fixed
      noBottomColumns
      displayEntries={false}
      searching={false}
      className="col-xl-12"
      data={data}
    />
  );
};

export default MentorsData;
