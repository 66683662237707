import { Row, Col } from "react-bootstrap";
import "./mentor.css";
import { MentorsModal } from "../../components/modal";
import Table from "./table/index";
import { tableColumns } from "./table/table-colums";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { Delete } from "../mentors/del-mentor";

export const Mentors = () => {
  const [table, setTable] = useState({});
  const [deleteShow, setDeleteShow] = useState(false);
  const [currentMentorId, setCurrentMentorId] = useState(null);
  const [lgShow, setLgShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [selectedMentor, setSelectedMentor] = useState("");

  const handleDeleteClose = () => setDeleteShow(false);

  const handleEditClick = (entry) => {
    setSelectedMentor(entry);
    setEditShow(true);
  };

  const handleDeleteOpen = (id) => {
    setDeleteShow(true);
    setCurrentMentorId(id);
  };

  let tableRows = {
    rows: [{}],
  };

  const getData = async () => {
    const mentorCol = collection(db, "mentorUids");
    const MentorSnapshot = await getDocs(mentorCol);
    MentorSnapshot.forEach((doc) => {
      let entry = {
        uid: doc.id ? doc.id : "",
        firstName: doc.data().FirstName ? doc.data().FirstName : "",
        lastName: doc.data().LastName ? doc.data().LastName : "",
        emailAddress: doc.data().Email ? doc.data().Email : "",
        gender: doc.data().Gender ? doc.data().Gender : "",
        branch: doc.data().Branch ? doc.data().Branch : "",
        unit: doc.data().Unit ? doc.data().Unit : "",
        serviceEndDate: doc.data().ServEndDate ? String(doc.data().ServEndDate) : "",

        delete: (
          <div>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                handleDeleteOpen(doc.id);
              }}
            >
              Delete
            </Button>
          </div>
        ),

        edit: (
          <div>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                handleEditClick(entry);
              }}
            >
              Edit
            </Button>
          </div>
        ),
      };
      // console.log("Entry", entry)
      tableRows.rows.push(entry);
    });

    setTable({ ...tableColumns.data, ...tableRows });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    console.log("Selected Mentor", selectedMentor);
  }, [selectedMentor])

  return (
    <Col className="d-flex flex-column justify-content-start pt-4 sideContainer">
      <Row className="w-100">
        <MentorsModal lgShow={lgShow} setLgShow={setLgShow} selectedMentor={selectedMentor} setSelectedMentor={setSelectedMentor} getData={getData} />
        <MentorsModal lgShow={editShow} setLgShow={setEditShow} selectedMentor={selectedMentor} setSelectedMentor={setSelectedMentor} getData={getData} isEdit={true}/>
        <Delete
          currentMentorId={currentMentorId}
          show={deleteShow}
          close={handleDeleteClose}
          getData={getData}
        />
        <div className="d-flex align-items-center justify-content-between text-primary"></div>
      </Row>
      <Row className="w-100 m-4">
        <Table className="col-xl-10" data={table} getData={getData} />
      </Row>
    </Col>
  );
};
