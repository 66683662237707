import { Modal, Button } from 'react-bootstrap';

export const Alert = ({ show, close, title, message }) => {
    return (
        <div>
            <Modal show={show} onHide={close} backdrop='static' keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => close()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
