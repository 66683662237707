import * as React from 'react';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { Container, Row, Col, Form } from "react-bootstrap";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import logo from '../../assets/battle-buddies.png'
import { useNavigate } from 'react-router-dom';
import { Alert } from '../../components/alert';

import './login.css';

export const Login = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [authError, setAuthError] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();
    console.log('test', email, password);
    await signInWithEmailAndPassword(auth, email, password)
      .then((s) => {
        setAuthError('');
        navigate('/home/mentors');
      })
      .catch((e) => {
        setAuthError(e.message)
        setShowAlert(true);
      })
  }

  return (
    <div>
      <Alert show={showAlert} close={() => setShowAlert(false)} title={'Login Problem'} message={'There was an error logging in. \n\n' + authError}/>
      <Container className="d-flex align-items-center">
        <Row id='page-container'>
          <Col className="d-flex flex-column align-items-center pt-5y">
            <Row>
              <Col>
                <img src={logo} height="250" width="400" alt='' />
              </Col>
            </Row>
            <Row className=''>
            </Row>
            <Row className="w-50">
                <Form onSubmit={(event) => handleSubmit(event)} className="d-flex flex-column align-items-center w-4">
                  <Form.Group className="mb-4 w-75" controlId="formBasicEmail">
                    <Form.Control type="email" placeholder="E-mail" onChange={(event) => setEmail(event.target.value)} />
                  </Form.Group>
                  <Form.Group className="mb-4 w-75" controlId="formBasicPassword">
                    <Form.Control type="password" placeholder="Password" onChange={(event) => setPassword(event.target.value)} />
                  </Form.Group>
                  <Button type="submit" size='medium' className="btn btn-danger" variant="contained" sx={{ pr: 6, pl: 6, borderRadius: 2 }}>Log In</Button>
                </Form>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
