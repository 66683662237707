import { Row, Col } from "react-bootstrap";

export const Analytics = () => {

    return (
        <Col className="d-flex flex-column justify-content-start pt-4 sideContainer">
            <Row className="w-100">
                <h1>Analytics</h1>
                <div className="d-flex align-items-center justify-content-between text-primary">
                </div>
            </Row>
            <Row className="w-100 m-4">
            </Row>
        </Col>
    );
};
